<template>
  <TargetKpiKorporatForm mode="Tambah" module="Target Indikator Kinerja"> </TargetKpiKorporatForm>
</template>

<script>
import TargetKpiKorporatForm from './form';

const TargetKpiKorporatAdd = {
  name: 'TargetKpiKorporatAdd',
  components: { TargetKpiKorporatForm },
};

export default TargetKpiKorporatAdd;
</script>
